import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import AuthDetails from "../../pages/auth/details/AuthDetails";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from "../../api/firebase";
import { toast } from "sonner";
import {FaRightFromBracket, FaTableColumns} from "react-icons/fa6";

const Navbar: React.FC = () => {
    const location = useLocation();
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const isCurrentPage = (path: string) => location.pathname === path;

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    }

    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };

    const signOutClick = () => {
        signOut(auth)
            .then(() => {
                setDropdownActive(!dropdownActive);
                toast.success("Successfully signed out.");
            }).catch((error => {
            toast.error("An error occurred whilst signing out.", {
                description: error
            });
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownActive(false);
            }
        };

        if (dropdownActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownActive]);

    return (
        <>
            <AuthDetails loggedIn={setLoggedIn} />

            <div className={"nav-bar"}>
                <div className={"nav-bar__icon"}>
                    <h1 className={"nav-bar__title"}>
                        <a href={'/'}>HealthMate</a>
                    </h1>
                </div>

                <div className={'nav-bar__links'}>
                    <a className={`${isCurrentPage('/home') ? 'current' : (isCurrentPage('/') ? 'current' : '')}`} href={'/home'}>
                        Home
                    </a>

                    <a className={`${isCurrentPage('/bmi-calculator') ? 'current' : ''}`} href={'/bmi-calculator'}>
                        BMI Calculator
                    </a>

                    <a className={`${isCurrentPage('/weight-converter') ? 'current' : ''}`} href={'/weight-converter'}>
                        Weight Converter
                    </a>

                    <a className={`${isCurrentPage('/height-converter') ? 'current' : ''}`} href={'/height-converter'}>
                        Height Converter
                    </a>
                </div>

                <div className={'nav-bar--buttons'}>
                    <button className={'hamburger-button'} onClick={toggleMenu} aria-label="menu">
                        <CiMenuBurger />
                    </button>

                    <div className="account-container" ref={dropdownRef}>
                        {isLoggedIn ? (
                            <button className={'nav-bar--account_logged_in'} onClick={toggleDropdown} aria-label={"account button"}>
                                <IoPersonCircleOutline />
                            </button>
                        ) : (
                            <Link to={'/login'}>
                                <button className={'nav-bar--account'} aria-label={"login button"}>
                                    Login
                                </button>
                            </Link>
                        )}

                        <div className={`dropdown ${dropdownActive ? 'dropdown-active' : ''}`}>
                            <Link to="/manage-account">
                                <button className="dropdown-button" aria-label={"dashboard"}><FaTableColumns /> Dashboard</button>
                            </Link>

                            <button className="dropdown-button" onClick={signOutClick} aria-label={"sign out"}><FaRightFromBracket /> Logout</button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`menu-overlay ${menuActive ? 'engaged' : ''}`}
                onClick={toggleMenu}>
            </div>

            <div className={`menu ${menuActive ? 'engaged' : ''}`}>
                <div className="menu-header">
                    <button className="close-button" onClick={toggleMenu} aria-label={"Close Menu"}>
                        &times;
                    </button>

                    <p className={'menu-header__text'}>HealthMate</p>
                </div>

                <div className="menu-links">
                    <a className={`account ${isCurrentPage('/login') ? 'current' : ''}`} href={`${isLoggedIn ? '/manage-account' : '/login'}`}>
                        {isLoggedIn ? 'Account' : 'Login'}
                    </a>

                    <a className={`${isCurrentPage('/home') ? 'current' : (isCurrentPage('/') ? 'current' : '')}`} href={'/home'}>
                        Home
                    </a>

                    <a className={`${isCurrentPage('/bmi-calculator') ? 'current' : ''}`} href={'/bmi-calculator'}>
                        BMI Calculator
                    </a>

                    <a className={`${isCurrentPage('/weight-converter') ? 'current' : ''}`} href={'/weight-converter'}>
                        Weight Converter
                    </a>

                    <a className={`${isCurrentPage('/height-converter') ? 'current' : ''}`} href={'/height-converter'}>
                        Height Converter
                    </a>
                </div>
            </div>
        </>
    );
};

export default Navbar;
