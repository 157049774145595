import React, { lazy, Suspense } from 'react';
import './styles.css'

const PrivacyLazy = lazy(() => import('./privacy'));
const PrivacyLoader = () => {
    return (
        <Suspense>
            <PrivacyLazy/>
        </Suspense>
    )
}

export default PrivacyLoader;