import React, { useEffect, useState } from 'react';
import './styles.css';
import {
    useKilogramsState,
    usePoundsState,
    useStoneState,
} from '../../utils';
import SubmitButton from '../buttons/submitButton';
import ResetButton from '../buttons/resetButton';
import {
    collection,
    query,
    where,
    getDocs,
    deleteDoc,
    doc,
    DocumentReference,
    addDoc,
    updateDoc,
    Timestamp,
    orderBy,
    limit,
} from 'firebase/firestore';
import { db, auth } from '../../api/firebase';
import { toast } from 'sonner';
import AuthDetails from '../../pages/auth/details/AuthDetails';
import { FaArrowUpLong, FaArrowDownLong, FaBan } from "react-icons/fa6";
import { TailSpin } from 'react-loading-icons';
import Toggle from "../toggle/toggle";

interface SavedData {
    id: string;
    bmi: number;
    date: Timestamp;
    user: string;
    feet?: string;
    inches?: string;
    pounds?: number;
    stone?: number;
    metres?: string;
    kilograms?: number;
}
const Dashboard = () => {
    const [goal, setGoal] = useState<string>('none');
    const [updateGoalVisible, setUpdateGoalVisible] = useState<boolean>(false);
    const [userData, setUserData] = useState<SavedData[]>([]);
    const [trendData, setTrendData] = useState<SavedData[]>([]);
    const [trendArrow, setTrendArrow] = useState<string>('none');
    const [trend, setTrend] = useState<string>('none');

    const [loading, setLoading] = useState<boolean>(true);

    const { stone, setStone } = useStoneState();
    const { pounds, setPounds } = usePoundsState();
    const { kilograms, setKilograms } = useKilogramsState();
    const [authUserID, setAuthUserID] = useState<string>('');

    const [stoneError, setStoneError] = useState<boolean>(false);
    const [poundsError, setPoundsError] = useState<boolean>(false);
    const [kilogramsError, setKilogramsError] = useState<boolean>(false);

    const [imperialToggle, setImperialToggle] = useState<boolean>(true);
    const [metricToggle, setMetricToggle] = useState<boolean>(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            setLoading(true);
            try {
                if (user) {
                    await downloadData();
                    await firestoreManagement();
                    setLoading(false);
                } else {
                    console.log("user not authenticated");
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                toast.loading("Unable to load user data", {
                    description: "Try again later.",
                });

                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleImperialToggle = () => {
        setImperialToggle(!imperialToggle);

        if (metricToggle) {
            setMetricToggle(false);
        }

        if (kilograms !== -1) {
            const stone = Math.floor(kilograms / 6.35029);
            const remainingPounds = (kilograms % 6.35029) / 0.453592;
            const pounds = Math.round(remainingPounds);

            setStone(stone);
            setPounds(pounds);
        }
    };

    const handleMetricToggle = () => {
        setMetricToggle(!metricToggle);

        if (imperialToggle) {
            setImperialToggle(false);
        }
        if (stone !== -1 && pounds !== -1) {
            const results: number = stone * 6.35029 + pounds * 0.453592;

            setKilograms(parseFloat(results.toFixed(2)));
        }
    };

    const stoneInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: string = event.target.value.trim();
        const parsedValue: number = inputValue === '' || isNaN(parseFloat(inputValue)) ? -1 : parseFloat(inputValue);
        setStone(parsedValue);
    };

    const poundsInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: string = event.target.value.trim();
        const parsedValue: number = inputValue === '' || isNaN(parseFloat(inputValue)) ? -1 : parseFloat(inputValue);
        setPounds(parsedValue);
    };

    const kilogramsInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: string = event.target.value.trim();

        const sanitizedInput: string = inputValue.replace(/[^0-9.]+/g, '');

        const isValidInput: boolean = sanitizedInput.split('.').length <= 2 && /^(\d+(\.\d{0,2})?)?$/.test(sanitizedInput);

        if (isValidInput) {
            const parsedValue: number = sanitizedInput === '' ? -1 : parseFloat(sanitizedInput);
            setKilograms(parsedValue > 0 && parsedValue <= 150 ? parsedValue : -1);
        }
    };

    const downloadData = async () => {
        setLoading(true);

        try {
            const user = auth.currentUser;

            if (user) {
                const q = query(collection(db, 'Dashboard'), where('user', '==', user.uid));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    const goalData = doc.data().goal;
                    setGoal(goalData);
                });

                setLoading(false);
            }
        } catch (error) {
            toast.error("Unable to load user data", {
                description: `Download Dashboard Data: Try again later.`,
            });

            console.log(error);
            setLoading(false);
        }
    };

    const firestoreManagement = async () => {
        setLoading(true);

        try {
            const user = auth.currentUser;

            if (user) {
                const q = query(
                    collection(db, 'savedData'),
                    where('user', '==', user.uid),
                    orderBy("date", "desc"),
                    limit(3)
                );

                const querySnapshot = await getDocs(q);

                const userDataArray: SavedData[] = [];
                querySnapshot.forEach((doc) => {
                    userDataArray.push({ id: doc.id, ...doc.data() } as SavedData);
                });

                setUserData(userDataArray);
                setLoading(false);
            }
        } catch (error) {
            toast.error('Unable to load user data', {
                description: 'Download General Data: Try again later.',
            });

            console.log(error);
            setLoading(false);
        }
    };

    const downloadTrendData = async () => {
        setLoading(true);

        try {
            const user = auth.currentUser;

            if (user) {
                const q = query(collection(db, "savedData"), where("user", "==", user.uid), orderBy("date", "desc"));

                const querySnapshot = await getDocs(q);

                const userDataArray: SavedData[] = [];
                querySnapshot.forEach((doc) => {
                    userDataArray.push({ id: doc.id, ...doc.data() } as SavedData);
                });

                setTrendData(userDataArray);
                setLoading(false);
            }
        } catch (error) {
            toast.error('Unable to load user data', {
                description: 'Download Trend Data: Try again later.',
            });

            console.log(error);
            setLoading(false);
        }
    };

    const convertStonesToKilograms = (stone: number, pounds: number) => {
        return stone * 6.35029 + pounds * 0.453592;
    };

    const convertKilogramsToStonesPounds = (kilograms: number) => {
        const totalPounds = kilograms * 2.20462;
        const stone = Math.floor(totalPounds / 14);
        const pounds = Math.round(totalPounds % 14);
        return { stone, pounds };
    };

    const calculateTrend = () => {
        if (trendData.length < 2) {
            setTrend("Not enough data to calculate");
            return;
        }

        const latestData = trendData[0];
        const oldestData = trendData[trendData.length - 1];

        let latestWeight: number | undefined;
        let oldestWeight: number | undefined;

        const goalIsMetric = goal.includes("kg");

        if (goalIsMetric) {
            latestWeight =
                latestData.kilograms ??
                (latestData.stone !== undefined && latestData.pounds !== undefined
                    ? convertStonesToKilograms(latestData.stone, latestData.pounds)
                    : undefined);

            oldestWeight =
                oldestData.kilograms ??
                (oldestData.stone !== undefined && oldestData.pounds !== undefined
                    ? convertStonesToKilograms(oldestData.stone, oldestData.pounds)
                    : undefined);
        } else {
            const latestKg = latestData.kilograms ?? -1;
            const oldestKg = oldestData.kilograms ?? -1;

            const latestImperial = latestKg !== -1 ? convertKilogramsToStonesPounds(latestKg) : null;
            const oldestImperial = oldestKg !== -1 ? convertKilogramsToStonesPounds(oldestKg) : null;

            latestWeight =
                latestData.stone !== undefined && latestData.pounds !== undefined
                    ? (latestData.stone * 14) + latestData.pounds
                    : latestImperial
                        ? (latestImperial.stone * 14) + latestImperial.pounds
                        : undefined;

            oldestWeight =
                oldestData.stone !== undefined && oldestData.pounds !== undefined
                    ? (oldestData.stone * 14) + oldestData.pounds
                    : oldestImperial
                        ? (oldestImperial.stone * 14) + oldestImperial.pounds
                        : undefined;
        }

        if (latestWeight !== undefined && oldestWeight !== undefined) {
            const weightDifference = latestWeight - oldestWeight;

            let trendMessage = `No significant change in weight`;

            if (weightDifference > 0) {
                trendMessage = `Total Gained ${weightDifference.toFixed(2)} ${goalIsMetric ? "kg" : "lbs"}`;
                setTrendArrow("gaining");
            } else if (weightDifference < 0) {
                trendMessage = `Total Lost ${Math.abs(weightDifference).toFixed(2)} ${goalIsMetric ? "kg" : "lbs"}`;
                setTrendArrow("loosing");
            }

            setTrend(trendMessage);
        } else {
            setTrend("Unable to calculate trend");
        }
    };

    useEffect(() => {
        downloadTrendData()
            .then(() => console.log("downloading trend data"));
        calculateTrend()

            // eslint-disable-next-line
    }, [userData]);


    const updateGoalBtn = async () => {
        setStoneError(false);
        setPoundsError(false);
        setKilogramsError(false);

        let newGoal = '';

        if (imperialToggle) {
            if (stone < 0 || pounds < 0) {
                if (stone < 0) {
                    setStoneError(true);
                } else {
                    setStoneError(false);
                }

                if (pounds < 0) {
                    setPoundsError(true);
                } else {
                    setPoundsError(false);
                }

                return;
            }

            newGoal = `${stone} st ${pounds} lbs`;
            setGoal(newGoal);
            setUpdateGoalVisible(false);
        }

        if (metricToggle) {
            if (kilograms < 0) {
                setKilogramsError(true);
                return;
            }

            newGoal = `${kilograms} kg`;
            setGoal(newGoal);
            setUpdateGoalVisible(false);
        }

        let docRef: DocumentReference;

        try {
            const querySnapshot = await getDocs(query(collection(db, 'Dashboard'), where('user', '==', authUserID)));

            if (!querySnapshot.empty) {
                docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, { goal: newGoal });
            } else {
                await addDoc(collection(db, 'Dashboard'), {
                    goal: newGoal,
                    user: authUserID,
                });
            }

            toast.success('Success', {
                description: 'Successfully updated your goal.',
            });
        } catch (error) {
            toast.error('Error Occurred whilst Saving', {
                description: `An error has occurred: ${error}`,
            });

            console.log(error);
        }

        setUpdateGoalVisible(false);
        setStone(-1);
        setPounds(-1);
        setKilograms(-1);
    };

    const removeGoal = async () => {
        try {
            const querySnapshot = await getDocs(query(collection(db, 'Dashboard'), where('user', '==', authUserID)));

            if (!querySnapshot.empty) {
                const docId = querySnapshot.docs[0].id;
                await deleteDoc(doc(db, 'Dashboard', docId));
                toast.success('Successfully deleted goal');
                setGoal('none');
            } else {
                toast.error('No goal found for the user');
            }
        } catch (error) {
            toast.error('An error occurred while removing the goal', {
                description: `${error}`,
            });
        }
    };

    const closeUpdGoalBtn = () => {
        setUpdateGoalVisible(!updateGoalVisible)
        setPoundsError(false)
        setKilogramsError(false);
        setStoneError(false);
    }

    return (
        <>
            <AuthDetails userId={setAuthUserID} />

            <div className="dashboard__container">
                <>
                    {updateGoalVisible && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h2>Update Goal</h2>

                                <Toggle
                                    leftLabel="Imperial"
                                    rightLabel="Metric"
                                    handleLeftLogic={handleImperialToggle}
                                    handleRightLogic={handleMetricToggle}
                                />

                                {imperialToggle && (
                                    <div className={"dashboard__fields--container"}>
                                        <div className={"fields--feet__container"}>
                                            <p>Stone</p>
                                            <input
                                                type="number"
                                                id="stoneInput"
                                                aria-label="Input Stone"
                                                value={stone === -1 ? '' : stone}
                                                onChange={stoneInputChange}
                                                className={`popup--input ${stoneError ? 'error' : ''}`}
                                            />
                                        </div>

                                        <div className={"fields--inches__container"}>
                                            <p>Pounds</p>
                                            <input
                                                type="number"
                                                id="poundsInput"
                                                aria-label="Input Pounds"
                                                value={pounds === -1 ? '' : pounds}
                                                onChange={poundsInputChange}
                                                className={`popup--input ${poundsError ? 'error' : ''}`}
                                            />
                                        </div>
                                    </div>
                                )}

                                {metricToggle && (
                                    <div className={"dashboard__fields--container"}>
                                        <div className={"fields--feet__container"}>
                                            <p>Kilograms</p>
                                            <input
                                                type="number"
                                                inputMode="decimal"
                                                id="kilogramsInput"
                                                aria-label="Input kg"
                                                value={kilograms === -1 ? '' : kilograms}
                                                onChange={kilogramsInputChange}
                                                className={`popup--input ${kilogramsError ? 'error' : ''}`}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className={"popup__goal--buttons"}>
                                    <SubmitButton title="Update" onClick={updateGoalBtn} />
                                    <ResetButton onClick={closeUpdGoalBtn} title="Cancel" />
                                </div>
                            </div>
                        </div>
                    )}

                    <fieldset className="dashboard__recent">
                        <legend className={"dashboard__title"}>Recent Weight</legend>
                        {loading ? (
                            <div className={"dashboard__loading"}>
                                <TailSpin/>
                            </div>
                        ) : (
                            <>
                                {userData.slice(0, 3).map((entry, index) => (
                                    <div key={index}
                                         className={`dashboard__recent--text ${goal !== 'none' ? 'visible' : ''}`}>
                                        {((entry.stone ?? 0) > 0 || (entry.pounds ?? 0) > 0) ? (
                                            <div>
                                            <span className="dashboard__recent--weight">
                                                {entry.stone} st, {entry.pounds} lbs
                                            </span> <br/>
                                            </div>
                                        ) : ((entry.kilograms ?? 0) > 0) ? (
                                            <div>
                                            <span className="dashboard__recent--weight">
                                                {entry.kilograms} kg
                                            </span> <br/>
                                            </div>
                                        ) : (
                                            <div>
                                            <span className="dashboard__recent--weight">
                                                Unable to find recent weight.
                                            </span> <br/>
                                            </div>
                                        )}

                                        <span className="dashboard__recent--date">
                                        {entry.date.toDate().toLocaleDateString()}
                                    </span>
                                    </div>
                                ))}
                            </>
                        )}
                    </fieldset>

                    <fieldset className="dashboard__recent">
                        <legend className={"dashboard__title"}>Recent BMI</legend>
                        {loading ? (
                            <div className={"dashboard__loading"}>
                                <TailSpin/>
                            </div>
                        ) : (
                            <>
                                {userData.slice(0, 3).map((entry, index) => (
                                    <div key={index}
                                         className={`dashboard__recent--text ${goal !== 'none' ? 'visible' : ''}`}>
                                        <span className="dashboard__recent--bmi"> BMI: {entry.bmi} </span> <br/>
                                        <span
                                            className="dashboard__recent--date"> {entry.date.toDate().toLocaleDateString()} </span>
                                    </div>
                                ))}
                            </>
                        )}
                    </fieldset>

                    <fieldset className="dashboard__trend">
                        <legend className={"dashboard__title-goal"}>Weight Loss</legend>
                        {loading ? (
                            <div className={"dashboard__loading"}>
                                <TailSpin/>
                            </div>
                        ) : (
                            <>
                                {trendArrow === "loosing" && (
                                    <div className={"dashboard__trend--container"}>
                                        <span className={"dashboard__trend--icon"}> <FaArrowDownLong/> </span>
                                        <p className={"dashboard__trend--text"}>
                                            {trend}
                                        </p>
                                    </div>
                                )}

                                {trendArrow === "gaining" && (
                                    <div className={"dashboard__trend--container"}>
                                        <span className={"dashboard__trend--icon"}> <FaArrowUpLong/> </span>

                                        <p className={"dashboard__trend--text"}>
                                            {trend}
                                        </p>
                                    </div>
                                )}

                                {trendArrow === "none" && (
                                    <div className={"dashboard__trend--container"}>
                                        <span className={"dashboard__trend--icon"}> <FaBan/> </span>
                                        <p className={"dashboard__trend--text"}>
                                            {trend}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </fieldset>

                    <fieldset className={`dashboard__goal  ${goal === "none" ? 'none' : ''}`}>
                        <legend className={`dashboard__title-goal`}>Weight Goal</legend>
                        {loading ? (
                            <div className={"dashboard__loading"}>
                                <TailSpin/>
                            </div>
                        ) : (
                            <>
                                {goal !== "none" ? (
                                    <div className={"dashboard__goal--container"}>
                                        <p className={"dashboard__goal--text"}>
                                            Current Goal: <span className={"dashboard__goal--span"}> {goal} </span>
                                        </p>

                                        <div className={"dashboard__button--container"}>
                                            <button className={"dashboard__goal--btn"} onClick={() => {
                                                setUpdateGoalVisible(!updateGoalVisible)
                                            }}>
                                                Update
                                            </button>

                                            <button className={"dashboard__goal--btn"} onClick={removeGoal}>
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={"dashboard__goal--none"}>
                                        <button className={"dashboard__goal--btn"} onClick={() => {
                                            setUpdateGoalVisible(!updateGoalVisible)
                                        }}>
                                            Set goal
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </fieldset>
                </>
            </div>
        </>
    );
};

export default Dashboard;