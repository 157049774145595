import React, { useState } from 'react';
import './signup.css';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { GoogleAuthProvider, getAuth, signInWithPopup, GithubAuthProvider, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { FaGoogle, FaGithub } from 'react-icons/fa6';
import AuthDetails from '../details/AuthDetails';
import { Toaster } from 'sonner';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../components/navbar/navbar';
import Footer from "../../../components/footer/footer";
import { IoWarning } from 'react-icons/io5';

const Signup = () => {
    const provider = new GoogleAuthProvider();
    const githubProvider = new GithubAuthProvider();
    const navigate = useNavigate();

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleAuthError = (error: any) => {
        let message: string;

        switch (error.code) {
            case "auth/invalid-email":
                message = "Invalid email format.";
                break;
            case "auth/email-already-in-use":
                message = "This email is already associated with an existing account.";
                break;
            case "auth/weak-password":
                message = "The password is too weak. Use at least 6 characters.";
                break;
            case "auth/internal-error":
                message = "An internal error occurred. Please try again.";
                break;
            default:
                message = `Error: ${error.message}`;
        }

        setErrorMessage(message);
        console.error(`Error Code: ${error.code} - ${message}`);
    };

    const signUpWithEmail = (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");

        const auth = getAuth();

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                if (user) {
                    updateProfile(user, { displayName: name })
                        .then(() => {
                            setIsButtonDisabled(true);
                            navigate("/manage-account");
                            setErrorMessage(""); // Clear errors on success
                        })
                        .catch(handleAuthError);
                }
            })
            .catch(handleAuthError);
    };

    const loginViaGoogle = () => {
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then(() => {
                setIsButtonDisabled(true);
                navigate("/manage-account");
                setErrorMessage(""); // Clear errors on success
            })
            .catch((error) => handleAuthError(error));
    };

    const loginViaGithub = () => {
        const auth = getAuth();

        signInWithPopup(auth, githubProvider)
            .then(() => {
                setIsButtonDisabled(true);
                navigate("/manage-account");
                setErrorMessage(""); // Clear errors on success
            })
            .catch((error) => handleAuthError(error));
    };

    return (
        <>
            <Navbar />

            <div className={"healthmate__login--body"}>
                <Toaster richColors expand={false} position={"top-center"} duration={2000}/>

                <p className={"healthmate__login--title"}>
                    Create a <span>HealthMate</span> Account
                </p>

                <p className={"healthmate__login--memo"}>
                    <span>Start your journey to a healthier you!</span> <br />
                    Sign up today and take control of your health with HealthMate.
                </p>

                <div className={"healthmate__login--alternative"}>
                    <button disabled={isButtonDisabled} onClick={loginViaGoogle}>
                        <FaGoogle />
                    </button>

                    <button disabled={isButtonDisabled} onClick={loginViaGithub}>
                        <FaGithub />
                    </button>
                </div>

                <div className={"healthmate__login--divider"}></div>

                <AuthDetails loggedIn={setIsButtonDisabled} displayNameVisible={true}/>

                {!isButtonDisabled && (
                    <>
                        <form onSubmit={signUpWithEmail}>
                            <div className={"healthmate__login--information"}>
                                <input
                                    aria-label={"Input Display Name"}
                                    type={"text"}
                                    placeholder={"Display Name"}
                                    className={"healthmate__login--input"}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />

                                <input
                                    aria-label={"Input Email"}
                                    type={"email"}
                                    placeholder={"Enter your Email"}
                                    className={"healthmate__login--input"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <div className={"healthmate__password--container"}>
                                    <input
                                        aria-label={"Input Password"}
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder={"Enter your Password"}
                                        className={"healthmate__login--password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    <button
                                        aria-label={"Password Visible"}
                                        className={"healthmate__password--visible"}
                                        type={'button'}
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? <BsEyeFill fill={'#b7b7b7'}/> : <BsEyeSlashFill fill={'#b7b7b7'}/>}
                                    </button>
                                </div>
                            </div>

                            {errorMessage && (
                                <div className={"fields--errors__container"}>
                                    <p className="errors">
                                        <span><IoWarning fill={'#fd4040'}/></span> {errorMessage}
                                    </p>
                                </div>
                            )}

                            <button
                                className={"healthmate__login--button"}
                                aria-label={"Create Account"}
                                type={"submit"}
                                disabled={isButtonDisabled || !email || !password || !name}
                            >
                                Create Account
                            </button>

                            <div className={"healthmate__login--support"}>
                                <a href={"/login"} aria-label={"Go To Login"} className={"healthmate__login--links"}>
                                    Already have an account?
                                    <span> Log in</span>
                                </a>
                            </div>
                        </form>
                    </>
                )}
            </div>

            <Footer />
        </>
    );
};

export default Signup;
