import React, { useState } from "react";
import "./styles.css";
import { FaCheck } from "react-icons/fa6";

interface ToggleProps {
    leftLabel: string;
    rightLabel: string;
    handleLeftLogic?: () => void;
    handleRightLogic?: () => void;
}

const Toggle: React.FC<ToggleProps> = ({ leftLabel, rightLabel, handleLeftLogic, handleRightLogic }) => {
    const [isLeftSelected, setIsLeftSelected] = useState(true);

    const handleToggle = () => {
        const newSelection = !isLeftSelected;
        setIsLeftSelected(newSelection);

        if (!newSelection && handleRightLogic) {
            handleRightLogic();
        } else if (newSelection && handleLeftLogic) {
            handleLeftLogic();
        }
    };

    return (
        <div className="toggle-container" onClick={handleToggle}>
            <div className={`toggle-button ${isLeftSelected ? "left-selected" : "right-selected"}`}>
                <div className="toggle-divider"></div>

                <div className="toggle-slider"></div>

                <div className={`toggle-option ${isLeftSelected ? "active" : ""}`}>
                    <span className="checkmark">{isLeftSelected ? <FaCheck/> : ""}</span>
                    {leftLabel}
                </div>

                <div className={`toggle-option ${!isLeftSelected ? "active" : ""}`}>
                    {rightLabel}
                    <span className="checkmark">{!isLeftSelected ? <FaCheck/> : ""}</span>
                </div>
            </div>
        </div>
    );
};

export default Toggle;
