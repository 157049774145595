import React, { lazy, Suspense } from 'react';
import './styles.css'

const NotFoundLazy = lazy(() => import('./NotFound'));
const NotFoundLoader = () => {
    return (
        <Suspense>
            <NotFoundLazy/>
        </Suspense>
    )
}

export default NotFoundLoader;