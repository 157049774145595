import React, { useEffect, useState } from 'react';
import AuthDetails from '../details/AuthDetails';
import './manageAccount.css';
import { collection, query, where, getDocs, Timestamp, deleteDoc, doc, orderBy } from 'firebase/firestore';
import { db, auth } from '../../../api/firebase';
import { MdDeleteOutline } from "react-icons/md";
import { toast, Toaster } from "sonner";
import { TailSpin } from 'react-loading-icons'
import { signOut, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../../components/dashboard/dashboard";
import Footer from "../../../components/footer/footer";
import Navbar from "../../../components/navbar/navbar";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface SavedData {
    id: string;
    bmi: number;
    date: Timestamp;
    user: string;

    feet?: string;
    inches?: string;
    pounds?: string;
    stone?: string;

    metres?: string;
    kilograms?: string;
}

const ManageAccount = () => {
    const [userData, setUserData] = useState<SavedData[]>([]);
    const [loggedInState, setLoggedInState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');
    const [refreshDashboard, setRefreshDashboard] = useState(0);

    const navigate = useNavigate();

    const signOutClick = () => {
        signOut(auth)
            .then(() => {
                navigate("/login")
                toast.success("Successfully signed out.");
            }).catch((error => {
            toast.error("An error occurred whilst signing out.", {
                description: error
            })
        }))
    }

    const editDisplayName = (newDisplayName: string) => {
        if (!auth.currentUser) {
            console.error("No user is currently logged in");
            return;
        }

        if (newDisplayName === '') {
            toast.error("Invalid display name.")
        } else {
            updateProfile(auth.currentUser, { displayName: newDisplayName })
                .then(() => {
                    console.log("Display name updated successfully");
                    toast.success("Display name updated successfully");
                    setIsModalOpen(false);
                    window.location.reload();
                })
                .catch((error) => {
                    console.error("Error updating display name:", error);
                    toast.error("Error updating display name", {
                        description: error.message
                    });
                });
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            try {
                if (user) {
                    await firestoreManagement();
                    console.log("Fetching user data");
                } else {
                    console.log("User not authenticated");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                toast.loading("Unable to load user data", {
                    description: "Try again later."
                });
            }
        });

        return () => unsubscribe();
    }, []);

    const formatToDdMmYyyy = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const deleteData = async (id: string) => {
        try {
            await deleteDoc(doc(db, "savedData", id));
            setUserData((prevData) => prevData.filter((data) => data.id !== id));
            toast.success("Successfully deleted data");

            setRefreshDashboard((prev) => prev + 1);
        } catch (error) {
            toast.error("An error occurred whilst loading your data", {
                description: `${error}`
            })
        }
    };

    const firestoreManagement = async () => {
        try {
            const user = auth.currentUser;

            if (user) {
                const q = query(collection(db, "savedData"), where("user", "==", user.uid), orderBy("date", "desc"));

                const querySnapshot = await getDocs(q);

                const userDataArray: SavedData[] = [];
                querySnapshot.forEach((doc) => {
                    userDataArray.push({ id: doc.id, ...doc.data() } as SavedData);
                });

                setUserData(userDataArray);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Unable to load user data", {
                description: "Try again later."
            });

            console.log(error)
        }
    };

    const stoneToKg = (stone: number, pounds: number) => {
        return (stone * 6.35) + (pounds * 0.453592);
    };

    const kgToStone = (kg: number) => {
        const totalPounds = kg / 0.453592;
        const stone = Math.floor(totalPounds / 14);
        const pounds = Math.round(totalPounds % 14);
        return { stone, pounds };
    };

    const chartData = {
        labels: userData
            .map(data => formatToDdMmYyyy(data.date.toDate()))
            .reverse(),
        datasets: [
            {
                label: 'Weight',
                data: userData
                    .map(data => {
                        if (data.kilograms) {
                            return data.kilograms;
                        } else {
                            const stone = parseInt(data.stone ?? '0');
                            const pounds = parseInt(data.pounds ?? '0');
                            return stoneToKg(stone, pounds);
                        }
                    })
                    .reverse(),
                fill: false,
                borderColor: '#67a7fd',
                tension: 0.1,
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem: any) {
                            const kg = tooltipItem.raw;
                            const { stone, pounds } = kgToStone(kg);
                            return `${stone} st ${pounds} lbs | ${kg.toFixed(3)} kg`;
                        }
                    }
                }
            }
        ] as any
    };

    return (
        <>
            <Navbar />

            <div className={"main"}>
                <Toaster richColors={true} expand={false} position={"top-center"} theme={"dark"} />

                <div className={"title__container"}>
                    <div className={"page__title"}>
                        <p className={"page__title-text"}>
                            <AuthDetails loginType={"manage"} loggedIn={setLoggedInState} />
                        </p>
                    </div>
                </div>

                {loggedInState && (
                    <Dashboard key={refreshDashboard} />
                )}

                {loggedInState && (
                    <div className={loggedInState ? "body__container" : "hidden"}>
                        {loading ? (
                            <TailSpin />
                        ) : (
                            <div className={"data__container"}>
                                {userData.length > 0 ? (
                                    userData.map((data: SavedData) => (
                                        <div key={data.id} className={loggedInState ? "data__information" : "hidden"}>
                                            {loggedInState && (
                                                <p>
                                                    {`BMI: ${data.bmi}`}
                                                </p>
                                            )}

                                            {loggedInState && (
                                                <p>
                                                    {`Date: ${formatToDdMmYyyy(data.date.toDate())}`}
                                                </p>
                                            )}

                                            {((data.feet ?? 0) > 0 || (data.inches ?? 0) > 0 || (data.stone ?? 0) > 0 || (data.pounds ?? 0) > 0) && (
                                                <div className="data__imperial-container">
                                                    <p className={"data__height-text"}>
                                                        {`Height: ${data.feet} ft ${data.inches} in`}
                                                    </p>

                                                    <p className={"data__weight-text"}>
                                                        {`Weight: ${data.stone} st ${data.pounds} lbs`}
                                                    </p>

                                                    <p className={"data__range-text"}>
                                                        Range: {data.bmi < 18.5
                                                        ? "Underweight"
                                                        : data.bmi >= 18.5 && data.bmi <= 24.9
                                                            ? "Healthy"
                                                            : data.bmi >= 25 && data.bmi <= 29.9
                                                                ? "Overweight"
                                                                : data.bmi >= 30
                                                                    ? "Obese"
                                                                    : "N/A"}
                                                    </p>
                                                </div>
                                            )}

                                            {((data.metres ?? 0) > 0 || (data.kilograms ?? 0) > 0) && (
                                                <div className="data__metric-container">
                                                    <p className={"data__height-text"}>
                                                        {`Height: ${data.metres} m`}
                                                    </p>

                                                    <p className={"data__weight-text"}>
                                                        {`Weight: ${data.kilograms} kg`}
                                                    </p>

                                                    <p className={"data__range-text"}>
                                                        Range: {data.bmi < 18.5
                                                        ? "Underweight"
                                                        : data.bmi >= 18.5 && data.bmi <= 24.9
                                                            ? "Healthy"
                                                            : data.bmi >= 25 && data.bmi <= 29.9
                                                                ? "Overweight"
                                                                : data.bmi >= 30
                                                                    ? "Obese"
                                                                    : "N/A"}
                                                    </p>
                                                </div>
                                            )}

                                            <button
                                                aria-label={"Delete"}
                                                className={"data__information-delete"}
                                                onClick={() => deleteData(data.id)}
                                            >
                                                <MdDeleteOutline />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p className={"data__error"}>
                                        Unable to find data associated with this account.
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {loggedInState && userData.length > 0 && (
                    <div className="chart-container">
                        <div className="chart">
                            <Line data={chartData} />
                        </div>
                    </div>
                )}

                {loggedInState && (
                    <div className={loggedInState ? "edit__displayName" : "hidden"}>
                        <AuthDetails loginType={'showDisplayName'} />

                        <button
                            aria-label={"edit__display-name"}
                            className={"display-name__edit"}
                            onClick={() => setIsModalOpen(true)}
                        >
                            Edit
                        </button>
                    </div>
                )}

                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Edit Display Name</h2>
                            <input
                                type="text"
                                value={newDisplayName}
                                onChange={(e) => setNewDisplayName(e.target.value)}
                                placeholder="Enter new display name"
                                className="modal-input"
                            />

                            <div className={"modal-content__buttons"}>
                                <button
                                    onClick={() => {
                                        editDisplayName(newDisplayName);
                                        setIsModalOpen(false);
                                    }}
                                    className="modal-submit-button"
                                >
                                    Submit
                                </button>

                                <button
                                    onClick={() => setIsModalOpen(false)}
                                    className="modal-close-button"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {loggedInState && (
                    <div className={loggedInState ? "personal__information" : "hidden"}>
                        <button
                            aria-label={"Sign Out"}
                            className={"sign-out__button"}
                            onClick={signOutClick}
                        >
                            Log Out
                        </button>
                    </div>
                )}
            </div>

            <Footer />
        </>
    );
};

export default ManageAccount;
