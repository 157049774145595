import React, { lazy, Suspense } from 'react';
const LazyLoad = lazy(() => import('./contact_us'));
const ContactLoader = () => {
    return (
        <Suspense>
            <LazyLoad />
        </Suspense>
    );
};

export default ContactLoader;