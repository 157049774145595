import React, { useState } from 'react';
import './resetAccPassword.css';
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import AuthDetails from "../details/AuthDetails";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";
import { IoWarning } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

const ResetPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [emailSentSuccess, setEmailSentSuccess] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>('');

    const handleAuthError = (errorCode: string) => {
        let message: string;

        switch (errorCode) {
            case "auth/invalid-email":
                message = "Invalid email format.";
                break;
            case "auth/user-not-found":
                message = "No existing user with this email.";
                break;
            case "auth/invalid-credential":
                message = "No account associated with this email.";
                break;
            default:
                message = "An unknown error occurred. Please try again.";
        }

        setErrorCode(message);
        console.error(`Error: ${message}`);
    };

    const resetPasswordSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorCode(''); // Reset error message
        setEmailSentSuccess(false);

        const auth = getAuth();

        try {

            await sendPasswordResetEmail(auth, email);
            setEmailSentSuccess(true);
        } catch (error: any) {
            if (error.code === "auth/invalid-email") {
                handleAuthError("auth/invalid-email");
            } else {
                handleAuthError("auth/user-not-found");
            }
        }
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            <Navbar />

            <div className={"main-page"}>
                <div className={"page__title"}>
                    <p className={"page__title-text"}>Reset Password</p>
                </div>

                <div className={"reset-page__container"}>
                    <input
                        aria-label={"Input Email"}
                        type={"email"}
                        placeholder={"Enter your Email"}
                        className={"healthmate__login--input"}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    {errorCode && (
                        <div className={"fields--errors__container"}>
                            <p className="errors">
                                <span><IoWarning fill={'#fd4040'} /></span> {errorCode}
                            </p>
                        </div>
                    )}

                    <button
                        className={"healthmate__login--button"}
                        aria-label={"Submit"}
                        type={"submit"}
                        disabled={isButtonDisabled || emailSentSuccess || !email}
                        onClick={resetPasswordSubmit}
                    >
                        Submit
                    </button>

                    {emailSentSuccess && (
                        <div className={"fields--success__container"}>
                            <p className="success">
                                <span className={"success_check"}><FaCheck /></span> An email has been sent to <span className={"success__email"}>{email}</span>
                            </p>
                        </div>
                    )}

                    <button
                        aria-label={"Return To Previous Page"}
                        className={"reset-page__return"}
                        onClick={goBack}
                    >
                        Change your mind?
                        <span>Return</span>
                    </button>
                </div>
            </div>

            <Footer />

            <AuthDetails loggedIn={setIsButtonDisabled} />
        </>
    );
};

export default ResetPassword;
