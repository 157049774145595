import React, { lazy, Suspense } from 'react';
import './styles.css'

const TermsLazy = lazy(() => import('./terms'));
const TermsLoader = () => {
    return (
        <Suspense>
            <TermsLazy/>
        </Suspense>
    )
}

export default TermsLoader;